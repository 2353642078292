$theme-colors: (
  "primary": #222
);

@import "~bootstrap/scss/bootstrap";
@import "~react-image-lightbox/style.css";
@import "./header";
@import "./level";
@import "./app-page";
@import "./game";
@import "./footer";
@import "./auth";


body {
  font-family: 'Titillium Web',sans-serif;
  background-color: #000;
  color: #d3d3d3;
}

.main-container{
  position: relative;
  padding-bottom: 100px;
  min-height: 100vh;
  &.home-page{
    min-height: initial;
  }
  .footer{
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
  }
}
.btn-primary{
  color: #fff;
  background: #222;
  border-color: #555;
  cursor: pointer;
  &:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  &:not(:disabled):not(.disabled):active, &:focus{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    box-shadow: 0 0 0 0.2rem rgba(173, 173, 173, 1);
  }
}
.static-page{
  a{
    color: #d3d3d3;
  }
}